import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { HeroStyles } from '../styles/GlobalStyles'
import SEO from '../components/SEO'
import BackgroundImage from '../components/BackgroundImage'
import Image from '../components/Image'
import Testimonial from '../components/Testimonial'
import bemWordmark from '../assets/logo/bem-wordmark.png'
import { getImageByName, getRandomTestimonial } from '../util/util'

const AboutStyles = styled.div`
  padding: var(--spacing);
  max-width: 700px;

  h2 {
    margin: 2rem 0 var(--spacing) 0;
    text-transform: uppercase;
    font-weight: bold;
  }

  h3 {
    margin: var(--spacing) 0 1.25rem 0;
    text-transform: uppercase;
    font-weight: bold;
  }

  p {
    font-size: 1.25rem;
    margin-bottom: 0;
  }
`

export default function AboutPage({ data }) {
  // Hero image
  const aboutImage = data?.file?.childImageSharp?.gatsbyImageData

  // About text
  const aboutData = data?.allGoogleSpreadsheetBemAbout?.nodes

  // Images
  const aboutImagesData = data?.allFile?.edges

  // Testimonials
  const randomTestimonial = getRandomTestimonial(data?.allGoogleSpreadsheetBemTestimonials?.nodes)

  return (
    <>
      <SEO title="About" />
      <BackgroundImage imageData={aboutImage} underHeader fullHeight>
        <HeroStyles>
          <div>
            <img className="logo" src={bemWordmark} alt="Bird's Eye Maine" />
            <h1>Discover More</h1>
            <p>Learn about the who, how, and what of Bird's Eye Maine</p>
          </div>
        </HeroStyles>
      </BackgroundImage>
      <AboutStyles>
        {aboutData?.map((section) => {
          const aboutImageData = getImageByName(aboutImagesData, section?.imageName)

          return (
            <div key={section?.id}>
              {section?.heading ? <h2>{section.heading}</h2> : null}
              {aboutImageData ? <Image imageData={aboutImageData} /> : null}
              {section?.subheading ? <h3>{section.subheading}</h3> : null}
              {section?.paragraph ? <p>{section.paragraph}</p> : null}
            </div>
          )
        })}
      </AboutStyles>
      <Testimonial quote={randomTestimonial?.quote} name={randomTestimonial?.name} />
    </>
  )
}

export const query = graphql`
  {
    file(sourceInstanceName: { eq: "background" }, name: { eq: "about" }) {
      name
      childImageSharp {
        id
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90, formats: [AUTO, WEBP])
      }
    }
    allFile(filter: { extension: { regex: "/(jpeg|jpg)/" }, sourceInstanceName: { eq: "about" } }) {
      edges {
        node {
          name
          childImageSharp {
            id
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, height: 500, quality: 90, formats: [AUTO, WEBP])
          }
        }
      }
    }
    allGoogleSpreadsheetBemAbout {
      nodes {
        id
        heading
        subheading
        paragraph
        imageName
      }
    }
    allGoogleSpreadsheetBemTestimonials {
      nodes {
        id
        name
        quote
      }
    }
  }
`
