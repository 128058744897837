import React from 'react'
import styled from 'styled-components'

const TestimonialStyles = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background-color: var(--gray);

  blockquote {
    max-width: 1024px;
    margin: 0 auto;
    padding: 3rem var(--spacing);
    text-align: center;
    font-style: normal;
    font-weight: normal;
    color: var(--darkBlue);
    font-size: 1.25rem;
    line-height: 1.7rem;

    .name {
      display: block;

      span {
        font-weight: bold;
        font-style: italic;
      }
    }
  }
`

const Testimonial = ({ quote, name }) => (
  <TestimonialStyles>
    <blockquote>
      &quot;{quote}&quot;
      <span className="name">
        {' '}
        &mdash;&nbsp;<span>{name}</span>
      </span>
    </blockquote>
  </TestimonialStyles>
)

export default Testimonial
